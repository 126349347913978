import {HStack, Text} from '@chakra-ui/react';
import {InformationIcon} from '../icons/informationIcon';
import {PropsWithChildren} from 'react';

export const AppAlertHeading = (props: PropsWithChildren) => {
  return <HStack color='error.icon'>
    <InformationIcon />
    <Text textStyle='textBodyMedium3' color='error.text'>
      {props.children}
    </Text>
  </HStack>;
};
