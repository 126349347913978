export enum ErrorCode {
    UNKNOWN,
    NETWORK_ERROR,
    VALIDATION_ERROR,
    UNAUTHENTICATED,
    EMPTY_INPUT_VALIDATION,
    EMPTY_SELECT_VALIDATION,
    INVALID_BULK_UPLOAD,
    LOGGED_OUT,
    INVALID_PERMISSIONS,
    BULK_UPLOAD_PARSE_ERROR_GENERIC,
    BULK_UPLOAD_PARSE_ERROR_MISMATCH_COLUMNS,
    BULK_UPLOAD_MAX_RECORD_SIZE,
    BULK_UPLOAD_INCONSISTENT_COLUMNS,
}
