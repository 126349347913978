import {TextDark800, TextMedium600} from '@RHEnvironmental/pattern-library-tokens';
import {Trans, useTranslation} from 'react-i18next';
import {isString} from '../../types/guards/isString';
import {Box, Text} from '@chakra-ui/react';

interface CardInfoProps {
  title: string;
  subTitle?: string;
  value: string;
  fontColour?: [string, string] | string;
  textStyle?: string;
}

export const CardInfo = ({
  title,
  value,
  subTitle,
  fontColour = [TextDark800, TextMedium600],
  textStyle = 'textBodyRegular3'
}: CardInfoProps) => {
  const {t} = useTranslation();

  return (
    <Box tabIndex={0}>
      <Text as="p" sx={{textStyle}} fontWeight="600" color={isString(fontColour) ? fontColour : fontColour[0]}>
        {t(title)}:
      </Text>
      {subTitle && (
        <Text as="p" sx={{textStyle}} fontWeight="500" color={isString(fontColour) ? fontColour : fontColour[1]}>
          {subTitle}
        </Text>
      )}
      <Text as="p" sx={{textStyle}} py="1px" color={isString(fontColour) ? fontColour : fontColour[1]}>
        {value}
      </Text>
    </Box>
  )
}
