import {ValidationError as ApiValidationError} from '../types/response/ValidationError';
import {ListItem, Container, List, Stack, Box} from '@chakra-ui/react';
import {getValidationErrorMessage} from '../api/validation/getValidationErrorMessage';
import {AppAlert} from '../components/AppAlert';
import {AppAlertHeading} from '../components/AppAlertHeading';
import {AppAlertDescription} from '../components/AppAlertDescription';
import {useTranslation} from 'react-i18next';
import {bulkUploadContainerSize} from '../pages/BulkUploadPage';

interface BulkUploadErrorListProps {
  errors: ApiValidationError[];
}

export const BulkUploadErrorList = (props: BulkUploadErrorListProps) => {
  const {t} = useTranslation();

  return <Container width="100%" maxWidth={'auto'} padding={0}>
    <AppAlert>
      <Stack alignItems="center">
        <Box maxWidth={bulkUploadContainerSize}>
          <AppAlertHeading>
            {t('A problem was encountered when processing your file')}
          </AppAlertHeading>
          <AppAlertDescription>
            <List>
              <ListItem>
                {props.errors.map((error, index) => {
                  return <div key={index}>{t(getValidationErrorMessage(error.code))}: {error.path}</div>;
                })}
              </ListItem>
            </List>
          </AppAlertDescription>
        </Box>
      </Stack>
    </AppAlert>
  </Container>;
};
