import {useToast} from '@chakra-ui/react';
import {EditLicenceLayout} from '../EditLicenceLayout';
import {ReactNode, useContext} from 'react';
import {EditLicence} from '../../types';
import {Endpoints} from '../../types/endpoints';
import {makeAxios} from '../../api/makeAxios';
import {Trans} from 'react-i18next';
import {AuthenticationContext} from '../../login/AuthenticationContext';
import {omit} from 'ramda';
import {computeLicenceMutation} from '../../create_licence/enter_details/computeLicenceMutation';
const axios = makeAxios();

export const EditLicenceSubmissionWrapper = ({header, children}:{header:string, children: ReactNode[]}) => {
  const toast = useToast();
  const {token} = useContext(AuthenticationContext);

  const editLicence = async (data: Partial<EditLicence>) => {
    const updatedLicence = omit(['procedure_types', 'organisation_id'], data);

    try {
      const computedData = computeLicenceMutation(updatedLicence);
      await axios.put(Endpoints.LICENCES, [computedData], {headers: {'Authorization': `Bearer ${token}`}});
      toast({
        title: <Trans>Licence successfully updated</Trans>,
        status: 'success',
        position: 'bottom-right',
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: <Trans>Licence could not be updated</Trans>,
        description: <Trans>Unknown error, please try again later</Trans>,
        status: 'error',
        position: 'bottom-right',
        duration: 9000,
      });
    }
  }

  return (
    <EditLicenceLayout header={header} onSubmit={editLicence}>
      {children}
    </EditLicenceLayout>
  )
}
