import {defineStyleConfig} from '@chakra-ui/react';
import {
  Box2DownLightX,
  Box2DownLightY,
  Box2DownLightSpread,
  Box2DownLightColor,
  Box2DownLightBlur,
  Spacing05,
  Spacing04,
  TextButton2FontFamily,
  TextButton2FontWeight,
  TextButton2LineHeight,
  TextButton2FontSize,
  TextButton2LetterSpacing,
  TextButton2TextCase,
  TextButton2TextDecoration,
  ButtonSecondaryBgFill,
  BorderWidth01,
  Opacity04,
  ButtonSecondaryBgFillHover,
  ButtonSecondaryBgFillPressed,
  BorderLight500,
  TextMedium600,
  BorderRadius02,
  TextLight500,
  Spacing03,
  Spacing02,
  TextButton3FontSize,
  TextButton3LineHeight,
  TextButton3FontWeight,
  TextButton3FontFamily, TextButton3LetterSpacing, TextButton3TextCase, TextButton3TextDecoration,
} from '@RHEnvironmental/pattern-library-tokens';
import {makeBoxShadow} from './makeBoxShadow';
import {px} from './px';
import {FallbackFonts} from './fallbackFonts';
import {figmaColors} from './colors';

export const Button = defineStyleConfig({
  variants: {
    primary: {
      color: 'primary.button.text',
      bg: 'primary.button.bg',
      boxShadow: makeBoxShadow(
        Box2DownLightX,
        Box2DownLightY,
        Box2DownLightBlur,
        Box2DownLightSpread,
        Box2DownLightColor
      ),
      height: 'auto',
      px: px(Spacing05),
      py: px(Spacing04),
      fontFamily: TextButton2FontFamily+FallbackFonts,
      fontWeight: TextButton2FontWeight,
      lineHeight: px(TextButton2LineHeight),
      fontSize: px(TextButton2FontSize),
      letterSpacing: TextButton2LetterSpacing,
      textTransform: TextButton2TextCase,
      textDecoration: TextButton2TextDecoration,
      _hover: {
        bg: 'primary.button.hover.bg',
        _disabled: {
          bg: 'primary.button.disabled.bg',
        }
      },
      _active: {
        bg: 'primary.button.active.bg',
      },
      _disabled: {
        bg: 'primary.button.disabled.bg',
        color: 'primary.button.disabled.text',
      }
    },
    secondary: {
      color: figmaColors['base1-500'],
      bg: ButtonSecondaryBgFill,
      borderColor: figmaColors['base1-500'],
      borderWidth: BorderWidth01,
      boxShadow: makeBoxShadow(
        Box2DownLightX,
        Box2DownLightY,
        Box2DownLightBlur,
        Box2DownLightSpread,
        Box2DownLightColor
      ),
      px: px(Spacing05),
      py: px(Spacing04),
      height: 'auto',
      fontFamily: TextButton2FontFamily+FallbackFonts,
      fontWeight: TextButton2FontWeight,
      lineHeight: px(TextButton2LineHeight),
      fontSize: px(TextButton2FontSize),
      letterSpacing: TextButton2LetterSpacing,
      textTransform: TextButton2TextCase,
      textDecoration: TextButton2TextDecoration,
      _hover: {
        bg: ButtonSecondaryBgFillHover,
        borderColor: figmaColors['base1-600'],
        color: figmaColors['base1-600'],
        _disabled: {
          opacity: Opacity04,
        }
      },
      _active: {
        bg: ButtonSecondaryBgFillPressed,
        borderColor: figmaColors['base1-700'],
        color: figmaColors['base1-700'],
      },
      _disabled: {
        opacity: Opacity04,
      }
    },
    quaternary: {
      color: figmaColors['neutral-500'],
      bg: ButtonSecondaryBgFill,
      borderColor: figmaColors['neutral-500'],
      borderWidth: BorderWidth01,
      px: px(Spacing03),
      py: px(Spacing02),
      height: 'auto',
      fontFamily: TextButton3FontFamily+FallbackFonts,
      fontWeight: TextButton3FontWeight,
      lineHeight: px(TextButton3LineHeight),
      fontSize: px(TextButton3FontSize),
      letterSpacing: TextButton3LetterSpacing,
      textTransform: TextButton3TextCase,
      textDecoration: TextButton3TextDecoration,
      _hover: {
        bg: ButtonSecondaryBgFillHover,
        borderColor: figmaColors['neutral-600'],
        color: figmaColors['neutral-600'],
        _disabled: {
          opacity: Opacity04,
        }
      },
      _active: {
        bg: ButtonSecondaryBgFillPressed,
        borderColor: figmaColors['neutral-700'],
        color: figmaColors['neutral-700'],
      },
      _disabled: {
        opacity: Opacity04,
      }
    },
    tertiary: {
      color: figmaColors['base1-500'],
      textStyle: 'textButton2',
      height: 'auto',
      px: px(Spacing05),
      py: px(Spacing04),
      _hover: {
        color: figmaColors['base1-600'],
      },
      _active: {
        color: figmaColors['base1-700'],
      }
    },
    linkPrimary: {
      color: figmaColors['base1-500'],
      lineHeight: 1,
      height: '1em',
      padding: 0
    },
    link: {
      color: TextLight500
    },
    outline: {
      borderColor: BorderLight500,
      borderRadius: BorderRadius02,
      color: TextMedium600,
      fontWeight: 500,
    },
  },
  defaultProps: {
    variant: 'primary',
  },
});
