import {ValidationErrorCode} from '../../types/validation/ValidationErrorCode';

export const validationErrorMessages: Record<ValidationErrorCode, string> = {
  [ValidationErrorCode.INVALID_EMAIL]: 'Enter a valid email address',
  [ValidationErrorCode.MISSING_FIELD]: 'This field is required',
  [ValidationErrorCode.FIELD_EMPTY]: 'This field is required',
  [ValidationErrorCode.UNKNOWN]: 'An unknown validation error occurred',
  [ValidationErrorCode.INVALID_DATE]: 'Invalid date (year-month-day) (eg. 2001-01-14)',
  [ValidationErrorCode.INVALID_VALUE]: 'Invalid value, this field only supports specific values',
  [ValidationErrorCode.BULK_UPLOAD_UNKNOWN_FIELD]: 'This field is not recognised',
};
