import {PropsWithChildren} from 'react';
import {Link, Spinner} from '@chakra-ui/react';
import {usePostResourceLink} from '../api/client';
import {ApplicationErrorText} from '../error/ApplicationErrorText';

type ProtectedResourceLinkProps = PropsWithChildren<{
  path: string;
}>;

export const ProtectedResourceLink = (props: ProtectedResourceLinkProps) => {
  const {mutate, isLoading, isError, error} = usePostResourceLink();

  const onClick = () => {
    if (!isLoading) {
      mutate({
        params: {
          key: props.path,
        },
      }, {
        onSuccess: (response) => {
          window.open(response.url, '_blank');
        },
      });
    }
  };

  if (isError) {
    return <ApplicationErrorText error={error} />
  }

  return <Link variant="primary" href="#" onClick={onClick} data-testid="protected-resource-link__link">
    {isLoading ? <Spinner data-testid="protected-resource-link__loading" /> : props.children}
  </Link>
};
