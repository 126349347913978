import {CreateLicenceType, EditLicence} from '../../types';
import {formatDate, parse} from 'date-fns';
import {clone} from 'ramda';

type LicenceMutation = Partial<CreateLicenceType> | Partial<EditLicence>;

export const computeLicenceMutation = (input: LicenceMutation) => {
  const output = clone(input);

  if (input.issue_date) {
    const parsedIssuedDate = parse(input.issue_date, 'yyyy-MM-dd', new Date());
    output.issue_date = formatDate(parsedIssuedDate, 'dd/MM/yyyy');
  }

  return output;
};
