import {
  Box,
  Container,
  Heading,
  Select,
  useToast,
  VStack,
  Spacer,
  Button,
  HStack,
  Input,
  FormLabel
} from '@chakra-ui/react';
import {Trans, useTranslation} from 'react-i18next';
import {usePostBulkUpload} from '../api/client';
import {ChangeEvent, SyntheticEvent, useRef, useState} from 'react';
import {BulkUploadType} from '../bulkUpload/BulkUploadType';
import {useApplicationErrorMessage} from '../error/useApplicationErrorMessage';
import {PermissionsGuard} from '../admin/permissions/PermissionsGuard';
import {PERMISSION_LICENCE_BULK_CREATE} from '../admin/permissions/Permissions';
import {Link} from 'react-router-dom';
import {ProtectedResourceLink} from '../components/ProtectedResourceLink';
import {extractValidationError} from '../api/validation/extractValidationErrors';
import {ValidationError as ApiValidationError} from '../types/response/ValidationError';
import {BulkUploadErrorList} from '../bulkUpload/BulkUploadErrorList';
import {RenderIf} from '../components/RenderIf';

export const bulkUploadContainerSize = '540px';

export const BulkUploadPage = () => {
  const {t} = useTranslation();
  const {get: getErrorMessage} = useApplicationErrorMessage();
  const [type, setType] = useState<BulkUploadType>(BulkUploadType.PREMIS);
  const {mutate, isLoading} = usePostBulkUpload();
  const toast = useToast();
  const file = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<ApiValidationError[]>([]);
  const [stagedFileName, setStagedFileName] = useState('');

  const onChangeType = (event: ChangeEvent<HTMLSelectElement>) => {
    setType(event.target.value as BulkUploadType);
  }

  const actSubmit = (records: string) => {
    setErrors([]);
    mutate({
      data: {
        records: records,
        type,
      }
    }, {
      onSuccess: () => {
        toast({
          title: t('Success'),
          description: t('Bulk upload successful'),
          status: 'success',
          duration: 5000,
        });

        const input = file.current;
        if (input) {
          input.value = '';
          setStagedFileName('');
        }
      },
      onError: (error) => {
        const validationError = extractValidationError(error);
        setErrors(validationError?.validationErrors ?? []);

        toast({
          title: t('Error'),
          description: getErrorMessage(error),
          status: 'error',
          duration: 10000,
        })
      },
    });
  };

  const onSubmit = () => {
    if (file.current && file.current.files && file.current.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result == 'string') {
          actSubmit(reader.result);
        } else {
          toast({
            title: t('Error'),
            description: t('Unknown error, please try again later'),
            status: 'error',
            duration: 10000,
          })
        }
      };
      reader.readAsText(file.current.files[0]);
    }
  };

  const onFileChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];

    if (file) {
      setStagedFileName(file.name);
      return;
    }

    setStagedFileName('');
  };

  return <PermissionsGuard permission={PERMISSION_LICENCE_BULK_CREATE}>
    <VStack spacing={'20px'} gap={'40px'}>

      <RenderIf condition={errors.length > 0}>
        <BulkUploadErrorList errors={errors} />
      </RenderIf>

      <Container mt={'40px'} width={'540px'} maxWidth={'75%'}>
        <Heading as={'h2'} variant={'Lt4'}>
          <Trans>Bulk Upload</Trans>
        </Heading>
      </Container>

      <Container width={'540px'} maxWidth={'75%'} >
        <FormLabel>{t('Licence Type')}</FormLabel>
        <Select onChange={onChangeType} width={'100%'}>
          <option value={BulkUploadType.PREMIS} selected={type === BulkUploadType.PREMIS}>
            <Trans>Premises</Trans>
          </option>
          <option value={BulkUploadType.PRACTITIONER} selected={type === BulkUploadType.PRACTITIONER}>
            <Trans>Practitioner</Trans>
          </option>
          <option value={BulkUploadType.VEHICLE} selected={type === BulkUploadType.VEHICLE}>
            <Trans>Vehicle</Trans>
          </option>
        </Select>
      </Container>

      <Container maxWidth={bulkUploadContainerSize} >
        <VStack>
          <HStack width="100%">
            <FormLabel>{t('Upload csv file')}</FormLabel>
            <Input type="file" data-testid="bulk-upload-page__file" ref={file} accept="text/csv" hidden onChange={onFileChange} />
            <Spacer />
            <Button variant="quaternary" onClick={() => file.current?.click()}>{t('Choose file')}</Button>
          </HStack>

          <RenderIf condition={stagedFileName.length > 0}>
            <Box style={{backgroundColor: 'rgba(0, 72, 194, 0.1)', padding: '5px', width: '100%', color: '#6E6764'}}>
              {stagedFileName}
            </Box>
          </RenderIf>

        </VStack>
      </Container>
      <Container maxWidth={bulkUploadContainerSize}>
        <Box>
          <ProtectedResourceLink path="/bulk-upload/Special-Procedures-Formatting.pdf">
            {t('Formatting Guidelines')}
          </ProtectedResourceLink>
        </Box>
        <Box>
          <ProtectedResourceLink path="/bulk-upload/premis-template.csv">
            {t('Download Premises Template')}
          </ProtectedResourceLink>
        </Box>
        <Box>
          <ProtectedResourceLink path="/bulk-upload/vehicle-template.csv">
            {t('Download Vehicle Template')}
          </ProtectedResourceLink>
        </Box>
        <Box>
          <ProtectedResourceLink path="/bulk-upload/practitioner-template.csv">
            {t('Download Practitioner Template')}
          </ProtectedResourceLink>
        </Box>
      </Container>
      <Container maxWidth={'540px'} >
        <HStack>
          <Box>
            <Link to={'/create-licence'}>
              <Button variant={'secondary'}>
                <Trans>Create Single</Trans>
              </Button>
            </Link>
          </Box>
          <Spacer />
          <Box alignSelf={'flex-end'}>
            <Button isLoading={isLoading} onClick={onSubmit} width={'100%'} data-testid={'bulk-upload-page__submit'}>
              <Trans>
                Submit
              </Trans>
            </Button>
          </Box>
        </HStack>
      </Container>
    </VStack>
  </PermissionsGuard>
};
