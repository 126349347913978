import {useContext, useState} from 'react';
import {makeAxios} from '../api/makeAxios';
import {AnyLicence} from '../types';
import {AuthenticationContext} from '../login/AuthenticationContext';
import {useLanguage} from './useLanguage';
import {useUnmount} from 'react-use';
const axios = makeAxios();

type SearchParams = Record<string, string>;

export const useQuery = () => {
  const language = useLanguage();
  const {token} = useContext(AuthenticationContext);
  const [results, setResults] = useState<AnyLicence[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [controller, setController] = useState<AbortController | null>(null);

  useUnmount(() => {
    if (controller) {
      controller.abort();
    }
  });

  const setSearchParamsProxy = (params: SearchParams) => {
    if (params != searchParams) {
      const controller = new AbortController();
      setSearchParams(params);
      setController(controller);
      setIsLoading(true);
      axios.get('/licences', {
        headers: {'Authorization': `Bearer ${token}`},
        params: {...params, language},
        signal: controller.signal
      })
        .then(res => setResults([...res.data.results]))
        .catch(error => setError(error instanceof Error ? error : new Error('An unknown error occurred')))
        .finally(() => setIsLoading(false));
    }
  };

  return {results, setSearchParams: setSearchParamsProxy, error, isLoading};
}
