import {useToast} from '@chakra-ui/react';
import {CreateLicenceStepLayout} from '../CreateLicenceStepLayout';
import {ReactNode, useContext, useEffect, useState} from 'react';
import {CreateLicenceType} from '../../types';
import {Endpoints} from '../../types/endpoints';
import {makeAxios} from '../../api/makeAxios';
import {CreateLicenceContext} from '../context/CreateLicenceContext';
import {CreateLicenceScreens} from '../../types/CreateLicence';
import {DEFAULT_VALUES} from '../FormValues';
import {Trans} from 'react-i18next';
import {AuthenticationContext} from '../../login/AuthenticationContext';
const axios = makeAxios();
import {parse, format} from 'date-fns';
import {useFormContext} from 'react-hook-form';
import {computeLicenceMutation} from './computeLicenceMutation';

enum Response {
  SUCCESS = 'success',
  ERROR = 'error',
  UNKNOWN = 'unknown',
}

export const LicenceSubmissionWrapper = ({header, children}:{header:string, children: ReactNode[]}) => {
  const toast = useToast();
  const {token} = useContext(AuthenticationContext);
  const [state, setState] = useState<Response>(Response.UNKNOWN);
  const {setStep} = useContext(CreateLicenceContext);
  const {reset} = useFormContext();

  const createNewLicence = async (data: Partial<CreateLicenceType>) => {
    try {
      const computedData = computeLicenceMutation(data);

      await axios.post(Endpoints.LICENCES, [computedData], {headers: {'Authorization': `Bearer ${token}`}});
      setState(Response.SUCCESS);
    } catch (e) {
      setState(Response.ERROR);
    }
  }

  useEffect(() => {
    switch(state){
    case Response.SUCCESS:
      toast({
        title: <Trans>Licence successfully created</Trans>,
        status: 'success',
        position: 'bottom-right',
        duration: 9000,
        isClosable: true,
      })
      reset(DEFAULT_VALUES);
      setStep(CreateLicenceScreens.BASIC_DETAILS);
      break;
    case Response.ERROR:
      toast({
        title: <Trans>Licence could not be created</Trans>,
        description: <Trans>Unknown error, please try again later</Trans>,
        status: 'error',
        position: 'bottom-right',
        duration: 9000,
      })
      break;
    }
  }, [state]);

  return (
    <CreateLicenceStepLayout header={header} onSubmit={createNewLicence}>
      {children}
    </CreateLicenceStepLayout>
  )
}
